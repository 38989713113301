import React, { Fragment } from "react";
import { Flex } from "antd";
import { Zoom } from "react-awesome-reveal";
// require('../style/welcome.css')

export default function Welcome() {
  const coverImage = "url(../images/Stanly_Dessy_029.jpg)";
  return (
    <Fragment>
      <div
        className="fullPageCover center"
        style={{
          backgroundImage: coverImage,
          height: "110%",
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          color: "white",
        }}
      >
        <Flex className="fullHeight" align="center" justify="flex-end" vertical>
          <Zoom>
            <p className="fontForum">THE WEDDING OF</p>
            <p className="fontCinzel fontExtraLarge noMarginPadding">Stanly</p>
            <p className="fontCinzel fontExtraLarge noMarginPadding">
              & Dessy{" "}
            </p>
            <p className="fontForum">19.01.25</p>
          </Zoom>
        </Flex>
      </div>
    </Fragment>
  );
}
