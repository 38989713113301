import React, { Fragment, useEffect, useState } from "react";
import {
  Layout,
  FloatButton,
  Flex,
  Button,
  Drawer,
  ConfigProvider,
} from "antd";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import Couple from "./Couple";
import Schedule from "./Schedule";
import Welcome from "./Welcome";
import { DrawerStyles } from "antd/es/drawer/DrawerPanel";
import { useParams } from "react-router-dom";
import Countdown from "./Countdown";
import { Slide, Zoom } from "react-awesome-reveal";
import Gift from "./Gift";
import Gallery from "./Gallery";
const { Content, Footer } = Layout;

require("../style/invitation.css");

export default function Invitation() {
  const [isOpen, setIsOpen] = useState(true);
  const [isMusicOn, setIsMusicOn] = useState(false);
  const params = useParams();
  const guestName = (params.guestName || "Guest")
    .replaceAll("+", " ")
    .replaceAll("&", " & ");
  const tyImage = "url(../images/WAW02647-1.jpg)";

  const [audio] = useState(
    new Audio(
      // "https://ia902208.us.archive.org/23/items/Carpenters_Close_To_You/Carpenters_Close_To_You.mp4",
      "../Close to You Mild Nawin.mp3",
    ),
  );

  useEffect(() => {
    isMusicOn ? audio.play() : audio.pause();
  }, [isMusicOn, audio]);

  const setMusic = () => {
    setIsMusicOn(!isMusicOn);
  };
  useEffect(() => {
    audio.addEventListener("ended", () => setIsMusicOn(false));
    return () => {
      audio.removeEventListener("ended", () => setIsMusicOn(false));
    };
  }, [audio]);

  const onClose = () => {
    setIsOpen(false);
    setIsMusicOn(true);
  };

  const drawerStyles: DrawerStyles = {
    body: {
      overflow: "hidden",
    },
    content: {
      backgroundColor: "transparent",
    },
    wrapper: {
      boxShadow: "none",
    },
  };

  return (
    <Fragment>
      <Layout className="layout">
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultHoverBorderColor: "white",
                defaultHoverColor: "black",
                defaultActiveBorderColor: "white",
                defaultActiveColor: "black",
              },
            },
          }}
        >
          <Content>
            <Drawer
              placement={"top"}
              closable={false}
              open={isOpen}
              onClose={onClose}
              key={"top"}
              styles={drawerStyles}
            >
              <Flex
                className="fontCormorant fullHeight fullWidth"
                gap="middle"
                align="center"
                justify="center"
                vertical
              >
                <p style={{ marginBottom: 0, color: "white" }}>
                  Dear Mr/Mrs/Ms.{" "}
                </p>
                <Zoom>
                  <h2 style={{ margin: 0, color: "white" }}>{guestName}</h2>
                </Zoom>
                <Slide direction="up">
                  <Button onClick={onClose}>OPEN INVITATION</Button>
                </Slide>
              </Flex>
            </Drawer>
            <FloatButton
              shape="circle"
              type="default"
              style={{ insetBlockEnd: 100 }}
              icon={
                isMusicOn ? <PauseCircleOutlined /> : <PlayCircleOutlined />
              }
              onClick={setMusic}
            />

            <Welcome />
            <Flex
              className="fullWidth"
              gap="middle"
              align="center"
              justify="space-between"
              vertical
            >
              <Flex
                className="fullWidth"
                align="center"
                justify="center"
                vertical
              >
                <Couple />
                <Countdown />
              </Flex>
              <Schedule />
              <Gift />
            </Flex>
            <Gallery />

            <div
              className="paddingMedium center "
              style={{
                backgroundImage: tyImage,
                height: "120%",
                backgroundPosition: "center bottom",
                backgroundSize: "cover",
                color: "white",
              }}
            >
              <Slide direction="up">
                <p className="marginTopExtraLarge fontMarcellus fontLarge">
                  THANK YOU,
                </p>
              </Slide>
              <Zoom>
                <p className="fontCinzel fontLarge noMarginPadding">
                  Stanly<span className="fontDancing fontMedium"> and </span>{" "}
                  Dessy{" "}
                </p>
              </Zoom>
            </div>
            <div className="copyright center paddingMedium">
              Copyright 2024 © All Rights Reserved. Created with love{" "}
              <b>Byvidé</b>
            </div>
          </Content>
        </ConfigProvider>
        <Footer>
          <FloatButton.BackTop />
        </Footer>
      </Layout>
    </Fragment>
  );
}
