import React, { Fragment } from "react";
import { Carousel, Image } from "antd";
import { Slide } from "react-awesome-reveal";

export default function Gallery() {
  const images = [
    "../images/Stanly_Dessy_052-1.jpg",
    "../images/Stanly_Dessy_064-1.jpg",
    "../images/WAW02200-2.jpg",
    "../images/WAW02280-1.jpg",
    "../images/WAW02286.jpg",
    "../images/WAW02382-1.jpg",
    "../images/WAW02536.jpg",
    "../images/WAW02573.jpg",
    "../images/WAW02581-1.jpg",
    "../images/WAW02632-1.jpg",
  ];
  return (
    <Fragment>
      <div className="center">
        <Slide direction="down">
          <p className="textBig">The</p>
          <p className="textCurly">Gallery </p>
        </Slide>
      </div>
      <div className="paddingMedium">
        <Carousel arrows autoplay style={{ width: "100%", maxWidth: "450px" }}>
          {images.map((value) => (
            <Image
              key={value}
              src={value}
              width={"100%"}
              style={{ objectFit: "cover" }}
            />
          ))}
        </Carousel>
      </div>
    </Fragment>
  );
}
